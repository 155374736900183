var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"materials"},[_c('UserData'),_c('MaterialsFilter',{staticClass:"d-xl-none"}),(_vm.recommendations && Object.keys(_vm.recommendations).length)?_c('div',[(_vm.user.specialities.length && _vm.user.diseases.length )?_c('div',[_c('div',{staticClass:"materials__title mb-4"},[_vm._v("Материалы для вас:")]),_c('div',{staticClass:"button button_pink px-12 d-inline-flex mb-4 materials__filter-button d-xl-none",on:{"click":_vm.openFilter}},[_c('svg',{staticClass:"mr-2",attrs:{"width":"25","height":"24","viewBox":"0 0 25 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M19.5078 12H21.6191","stroke":"white"}}),_c('path',{attrs:{"d":"M3.38281 12H15.0116","stroke":"white"}}),_c('path',{attrs:{"d":"M10.4531 5.98828H21.6206","stroke":"white"}}),_c('path',{attrs:{"d":"M3.38281 5.98828H5.96281","stroke":"white"}}),_c('path',{attrs:{"d":"M13.625 18.0117H21.62","stroke":"white"}}),_c('path',{attrs:{"d":"M3.38281 18.0117H9.12781","stroke":"white"}}),_c('path',{attrs:{"d":"M8.20703 8.125C9.44967 8.125 10.457 7.11764 10.457 5.875C10.457 4.63236 9.44967 3.625 8.20703 3.625C6.96439 3.625 5.95703 4.63236 5.95703 5.875C5.95703 7.11764 6.96439 8.125 8.20703 8.125Z","stroke":"white"}}),_c('path',{attrs:{"d":"M17.2578 14.25C18.5005 14.25 19.5078 13.2426 19.5078 12C19.5078 10.7574 18.5005 9.75 17.2578 9.75C16.0152 9.75 15.0078 10.7574 15.0078 12C15.0078 13.2426 16.0152 14.25 17.2578 14.25Z","stroke":"white"}}),_c('path',{attrs:{"d":"M11.375 20.2617C12.6176 20.2617 13.625 19.2544 13.625 18.0117C13.625 16.7691 12.6176 15.7617 11.375 15.7617C10.1324 15.7617 9.125 16.7691 9.125 18.0117C9.125 19.2544 10.1324 20.2617 11.375 20.2617Z","stroke":"white"}})]),_vm._v(" Фильтр ")]),_c('MaterialsCategories',{staticClass:"mb-7"}),(_vm.notEmpty)?_c('div',[(
          _vm.recommendationsList.text_materials &&
          _vm.recommendationsList.text_materials.length &&
          _vm.$route.params.type === 'text'
        )?_c('div',{staticClass:"materials__grid"},_vm._l((_vm.materialsListLength('text_materials')),function(i){return _c('Material',{key:_vm.recommendationsList.text_materials[i - 1].id,attrs:{"item":_vm.recommendationsList.text_materials[i - 1],"page":'TextDetail'},on:{"watchLater":function($event){return _vm.watchLater(
              _vm.recommendationsList.text_materials[i - 1].watch_later ? 'DELETE' : 'POST',
              _vm.recommendationsList.text_materials[i - 1].slug,
              'text_material'
            )},"favorite":function($event){return _vm.favorite(
              _vm.recommendationsList.text_materials[i - 1].favorite ? 'DELETE' : 'POST',
              _vm.recommendationsList.text_materials[i - 1].slug,
              'text_material'
            )}}})}),1):_vm._e(),(
          _vm.recommendationsList.podcasts &&
          _vm.recommendationsList.podcasts.length &&
          _vm.$route.params.type === 'podcasts'
        )?_c('div',{staticClass:"materials__grid"},_vm._l((_vm.materialsListLength('podcasts')),function(i){return _c('Material',{key:_vm.recommendationsList.podcasts[i - 1].id,attrs:{"item":_vm.recommendationsList.podcasts[i - 1],"page":'PodcastDetail'},on:{"watchLater":function($event){return _vm.watchLater(
              _vm.recommendationsList.podcasts[i - 1].watch_later ? 'DELETE' : 'POST',
              _vm.recommendationsList.podcasts[i - 1].slug,
              'podcast'
            )},"favorite":function($event){return _vm.favorite(
              _vm.recommendationsList.podcasts[i - 1].favorite ? 'DELETE' : 'POST',
              _vm.recommendationsList.podcasts[i - 1].slug,
              'podcast'
            )}}})}),1):_vm._e(),(
          _vm.recommendationsList.video_materials &&
          _vm.recommendationsList.video_materials.length &&
          _vm.$route.params.type === 'video'
        )?_c('div',{staticClass:"materials__grid"},_vm._l((_vm.materialsListLength('video_materials')),function(i){return _c('Material',{key:_vm.recommendationsList.video_materials[i - 1].id,attrs:{"item":_vm.recommendationsList.video_materials[i - 1],"page":'VideoDetail'},on:{"watchLater":function($event){return _vm.watchLater(
              _vm.recommendationsList.video_materials[i - 1].watch_later ? 'DELETE' : 'POST',
              _vm.recommendationsList.video_materials[i - 1].slug,
              'video_material'
            )},"favorite":function($event){return _vm.favorite(
              _vm.recommendationsList.video_materials[i - 1].favorite ? 'DELETE' : 'POST',
              _vm.recommendationsList.video_materials[i - 1].slug,
              'video_material'
            )}}})}),1):_vm._e(),(
          _vm.recommendationsList.diseases &&
          _vm.recommendationsList.diseases.length &&
          _vm.$route.params.type === 'diseases'
        )?_c('div',{staticClass:"materials__grid"},_vm._l((_vm.materialsListLength('diseases')),function(i,ind){return _c('DiseaseUpdate',{key:ind,attrs:{"item":_vm.recommendationsList.diseases[i - 1]}})}),1):_vm._e(),(
          _vm.recommendationsList.drugs &&
          _vm.recommendationsList.drugs.length &&
          _vm.$route.params.type === 'medications'
        )?_c('div',{staticClass:"materials__grid"},_vm._l((_vm.materialsListLength('drugs')),function(i){return _c('Medication',{key:_vm.recommendationsList.drugs[i - 1].id,attrs:{"item":_vm.recommendationsList.drugs[i - 1]},on:{"watchLater":function($event){return _vm.watchLater(
              _vm.recommendationsList.drugs[i - 1].watch_later ? 'DELETE' : 'POST',
              _vm.recommendationsList.drugs[i - 1].slug,
              'drug'
            )},"favorite":function($event){return _vm.favorite(
              _vm.recommendationsList.drugs[i - 1].favorite ? 'DELETE' : 'POST',
              _vm.recommendationsList.drugs[i - 1].slug,
              'drug'
            )}}})}),1):_vm._e(),(
          _vm.recommendationsList.events &&
          _vm.recommendationsList.events.length &&
          _vm.$route.params.type === 'events'
        )?_c('div',_vm._l((_vm.materialsListLength('events')),function(i){return _c('Event',{key:i,staticClass:"mb-6",staticStyle:{"background-color":"#fff"},attrs:{"event":_vm.recommendationsList.events[i - 1],"large":""}})}),1):_vm._e(),_c('div',{staticClass:"materials-page__buttons"},[(
            _vm.recommendationsList[_vm.types[_vm.$route.params.type]].length > _vm.listLength
          )?_c('div',{staticClass:"materials-page__load button button_pink",on:{"click":function($event){return _vm.loadMore(_vm.types[_vm.$route.params.type])}}},[_vm._v(" Загрузить ещё "),_c('span',{staticClass:"ml-2"},[_c('svg',{attrs:{"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M4.5 12.7501L4.5 12.0001H3V12.7501H4.5ZM19.5 12.7501C19.5 16.8923 16.1421 20.2501 12 20.2501L12 21.7501C16.9706 21.7501 21 17.7207 21 12.7501L19.5 12.7501ZM12 20.2501C7.85786 20.2501 4.5 16.8923 4.5 12.7501H3C3 17.7207 7.02944 21.7501 12 21.7501L12 20.2501ZM12 5.25012C16.1421 5.25012 19.5 8.60799 19.5 12.7501L21 12.7501C21 7.77956 16.9706 3.75012 12 3.75012V5.25012ZM7.56864 6.69856C8.8101 5.78779 10.3412 5.25012 12 5.25012V3.75012C10.0114 3.75012 8.17143 4.39596 6.68136 5.48912L7.56864 6.69856Z","fill":"#B36697"}}),_c('path',{attrs:{"d":"M8.08391 1.05776L6.37094 6.61929L11.9325 8.33225","stroke":"#B36697","stroke-width":"1.5"}})])])]):_vm._e(),(
            _vm.recommendationsList[_vm.types[_vm.$route.params.type]].length > _vm.count
          )?_c('div',{staticClass:"materials-page__up button button_empty-pink",on:{"click":function($event){return _vm.toTop()}}},[_vm._v(" К началу страницы "),_c('span',{staticClass:"ml-2"},[_c('svg',{attrs:{"width":"18","height":"10","viewBox":"0 0 18 10","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M1.25 8.75012L8.75 1.25012L16.25 8.75012","stroke":"currentColor","stroke-width":"1.5","stroke-linecap":"square"}})])])]):_vm._e()])]):_c('div',{staticClass:"favorites__list-container"},[_c('div',{staticClass:"favorites__title"},[_vm._v("Здесь пока пусто")]),_c('div',{staticClass:"favorites__descr"},[_vm._v(" Чтобы увидеть список материалов для вас - выберите хотя бы одну терапевтическую область и одну нозологию ")])])],1):_c('div',{staticClass:"favorites__list-container"},[_c('div',{staticClass:"favorites__title"},[_vm._v("Здесь пока пусто")]),_c('div',{staticClass:"favorites__descr"},[_vm._v(" Чтобы увидеть Материалы для вас - пройдите тест ")]),_c('router-link',{staticClass:"favorites__btn button button_pink mt-6",attrs:{"to":{ name: 'MaterialsSettings' }}},[_vm._v(" Настроить Мой AZ-MOST ")])],1)]):_c('div',{staticClass:"preloader"},[_c('Preloader',{})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }