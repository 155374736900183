<template>
  <div
    class="materials"
  >
    <UserData />
     <MaterialsFilter class="d-xl-none"/>
     <div
        v-if="recommendations && Object.keys(recommendations).length"
      >
    <div v-if="user.specialities.length && user.diseases.length ">
      <div class="materials__title mb-4">Материалы для вас:</div>
       <div @click="openFilter" class="button button_pink px-12 d-inline-flex mb-4 materials__filter-button d-xl-none">
        <svg
          class="mr-2"
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M19.5078 12H21.6191" stroke="white" />
          <path d="M3.38281 12H15.0116" stroke="white" />
          <path d="M10.4531 5.98828H21.6206" stroke="white" />
          <path d="M3.38281 5.98828H5.96281" stroke="white" />
          <path d="M13.625 18.0117H21.62" stroke="white" />
          <path d="M3.38281 18.0117H9.12781" stroke="white" />
          <path
            d="M8.20703 8.125C9.44967 8.125 10.457 7.11764 10.457 5.875C10.457 4.63236 9.44967 3.625 8.20703 3.625C6.96439 3.625 5.95703 4.63236 5.95703 5.875C5.95703 7.11764 6.96439 8.125 8.20703 8.125Z"
            stroke="white"
          />
          <path
            d="M17.2578 14.25C18.5005 14.25 19.5078 13.2426 19.5078 12C19.5078 10.7574 18.5005 9.75 17.2578 9.75C16.0152 9.75 15.0078 10.7574 15.0078 12C15.0078 13.2426 16.0152 14.25 17.2578 14.25Z"
            stroke="white"
          />
          <path
            d="M11.375 20.2617C12.6176 20.2617 13.625 19.2544 13.625 18.0117C13.625 16.7691 12.6176 15.7617 11.375 15.7617C10.1324 15.7617 9.125 16.7691 9.125 18.0117C9.125 19.2544 10.1324 20.2617 11.375 20.2617Z"
            stroke="white"
          />
        </svg>
        Фильтр
      </div>
      <MaterialsCategories class="mb-7" />
      <div v-if="notEmpty">
        <div
          class="materials__grid"
          v-if="
            recommendationsList.text_materials &&
            recommendationsList.text_materials.length &&
            $route.params.type === 'text'
          "
        >
          <Material
            v-for="i in materialsListLength('text_materials')"
            :item="recommendationsList.text_materials[i - 1]"
            :key="recommendationsList.text_materials[i - 1].id"
            :page="'TextDetail'"
             @watchLater="
              watchLater(
                recommendationsList.text_materials[i - 1].watch_later ? 'DELETE' : 'POST',
                recommendationsList.text_materials[i - 1].slug,
                'text_material'
              )
            "
            @favorite="
              favorite(
                recommendationsList.text_materials[i - 1].favorite ? 'DELETE' : 'POST',
                recommendationsList.text_materials[i - 1].slug,
                'text_material'
              )
            "
          />
        </div>
        <div
          class="materials__grid"
          v-if="
            recommendationsList.podcasts &&
            recommendationsList.podcasts.length &&
            $route.params.type === 'podcasts'
          "
        >
          <Material
            v-for="i in materialsListLength('podcasts')"
            :item="recommendationsList.podcasts[i - 1]"
            :key="recommendationsList.podcasts[i - 1].id"
            :page="'PodcastDetail'"
             @watchLater="
              watchLater(
                recommendationsList.podcasts[i - 1].watch_later ? 'DELETE' : 'POST',
                recommendationsList.podcasts[i - 1].slug,
                'podcast'
              )
            "
            @favorite="
              favorite(
                recommendationsList.podcasts[i - 1].favorite ? 'DELETE' : 'POST',
                recommendationsList.podcasts[i - 1].slug,
                'podcast'
              )
            "
          />
        </div>
        <div
          class="materials__grid"
          v-if="
            recommendationsList.video_materials &&
            recommendationsList.video_materials.length &&
            $route.params.type === 'video'
          "
        >
          <Material
            v-for="i in materialsListLength('video_materials')"
            :item="recommendationsList.video_materials[i - 1]"
            :key="recommendationsList.video_materials[i - 1].id"
            :page="'VideoDetail'"
             @watchLater="
              watchLater(
                recommendationsList.video_materials[i - 1].watch_later ? 'DELETE' : 'POST',
                recommendationsList.video_materials[i - 1].slug,
                'video_material'
              )
            "
            @favorite="
              favorite(
                recommendationsList.video_materials[i - 1].favorite ? 'DELETE' : 'POST',
                recommendationsList.video_materials[i - 1].slug,
                'video_material'
              )
            "
          />
        </div>
        <div
          class="materials__grid"
          v-if="
            recommendationsList.diseases &&
            recommendationsList.diseases.length &&
            $route.params.type === 'diseases'
          "
        >
          <DiseaseUpdate
            v-for="(i, ind) in materialsListLength('diseases')"
            :item="recommendationsList.diseases[i - 1]"
            :key="ind"
          />
        </div>
        <div
          class="materials__grid"
          v-if="
            recommendationsList.drugs &&
            recommendationsList.drugs.length &&
            $route.params.type === 'medications'
          "
        >
          <Medication
             @watchLater="
              watchLater(
                recommendationsList.drugs[i - 1].watch_later ? 'DELETE' : 'POST',
                recommendationsList.drugs[i - 1].slug,
                'drug'
              )
            "
            @favorite="
              favorite(
                recommendationsList.drugs[i - 1].favorite ? 'DELETE' : 'POST',
                recommendationsList.drugs[i - 1].slug,
                'drug'
              )
            "
            v-for="i in materialsListLength('drugs')"
            :item="recommendationsList.drugs[i - 1]"
            :key="recommendationsList.drugs[i - 1].id"
          />
        </div>
        <div
          v-if="
            recommendationsList.events &&
            recommendationsList.events.length &&
            $route.params.type === 'events'
          "
        >
          <Event
            v-for="i in materialsListLength('events')"
            :key="i"
            :event="recommendationsList.events[i - 1]"
            class="mb-6"
            large
            style="background-color: #fff"
          />
        </div>
        <div class="materials-page__buttons">
          <div
            class="materials-page__load button button_pink"
            v-if="
              recommendationsList[types[$route.params.type]].length > listLength
            "
            @click="loadMore(types[$route.params.type])"
          >
            Загрузить ещё
            <span class="ml-2">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.5 12.7501L4.5 12.0001H3V12.7501H4.5ZM19.5 12.7501C19.5 16.8923 16.1421 20.2501 12 20.2501L12 21.7501C16.9706 21.7501 21 17.7207 21 12.7501L19.5 12.7501ZM12 20.2501C7.85786 20.2501 4.5 16.8923 4.5 12.7501H3C3 17.7207 7.02944 21.7501 12 21.7501L12 20.2501ZM12 5.25012C16.1421 5.25012 19.5 8.60799 19.5 12.7501L21 12.7501C21 7.77956 16.9706 3.75012 12 3.75012V5.25012ZM7.56864 6.69856C8.8101 5.78779 10.3412 5.25012 12 5.25012V3.75012C10.0114 3.75012 8.17143 4.39596 6.68136 5.48912L7.56864 6.69856Z"
                  fill="#B36697"
                />
                <path
                  d="M8.08391 1.05776L6.37094 6.61929L11.9325 8.33225"
                  stroke="#B36697"
                  stroke-width="1.5"
                />
              </svg>
            </span>
          </div>
          <div
            class="materials-page__up button button_empty-pink"
            @click="toTop()"
             v-if="
              recommendationsList[types[$route.params.type]].length > count
            "
          >
            К началу страницы
            <span class="ml-2">
              <svg
                width="18"
                height="10"
                viewBox="0 0 18 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.25 8.75012L8.75 1.25012L16.25 8.75012"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="square"
                />
              </svg>
            </span>
          </div>
        </div>
      </div>
      <div class="favorites__list-container" v-else>
        <div class="favorites__title">Здесь пока пусто</div>
        <div class="favorites__descr">
          Чтобы увидеть список материалов для вас - выберите хотя бы одну
          терапевтическую область и одну нозологию
        </div>
      </div>
    </div>

    <div class="favorites__list-container" v-else>
      <div class="favorites__title">Здесь пока пусто</div>
      <div class="favorites__descr">
        Чтобы увидеть Материалы для вас - пройдите тест
      </div>
      <router-link
        :to="{ name: 'MaterialsSettings' }"
        class="favorites__btn button button_pink mt-6"
      >
        Настроить Мой AZ-MOST
      </router-link>
    </div>
    </div>
    <div v-else class="preloader">
        <Preloader class="" />
      </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import UserData from "../../components/pageComponents/cabinet/UserData.vue";
import MaterialsCategories from "../../components/pageComponents/cabinet/MaterialsCategories.vue";
import Material from "../../components/pageComponents/Material.vue";
import Event from "../../components/main/events/Event.vue";
import Medication from "../../components/pageComponents/Medication.vue";
import DiseaseUpdate from "@/components/pageComponents/DiseaseUpdate.vue";
import MaterialsFilter from '../../components/pageComponents/cabinet/MaterialsFilter.vue';
import { bus } from '@/main'
import Preloader from "@/components/Preloader.vue";
export default {
  components: {
    UserData,
    MaterialsCategories,
    Material,
    Event,
    Medication,
    DiseaseUpdate,
    MaterialsFilter,
    Preloader
  },
  name: "MaterialsDetail",
  data: () => ({
    count: 3,
    listLength: 6,
    types: {
      text: "text_materials",
      video: "video_materials",
      podcasts: "podcasts",
      diseases: "diseases",
      medications: "drugs",
      events: "events",
    },
  }),
  computed: {
    ...mapGetters(["user", "recommendations"]),
    currentNosologys: {
      get() {
        return this.$store.state.materialsFilter.materialsNosology;
      },
      set(value) {
        this.$store.commit("updateMatNos", value);
      },
    },
    currentDiseases: {
      get() {
        return this.$store.state.materialsFilter.materialsDisease;
      },
      set(value) {
        this.$store.commit("updateMatDis", value);
      },
    },
    notEmpty() {
      switch (this.$route.params.type) {
        case "text":
          return !!this.recommendationsList.text_materials.length;
        case "podcasts":
          return !!this.recommendationsList.podcasts.length;
        case "video":
          return !!this.recommendationsList.video_materials.length;
        case "events":
          return !!this.recommendationsList.events.length;
        case "medications":
          return !!this.recommendationsList.drugs.length;
        case "diseases":
          return !!this.recommendationsList.diseases.length;
      }
    },
    notEmptyTabs() {
      return Object.keys(this.recommendationsList).filter(
        (el) => this.recommendationsList[el].length
      );
    },
    recommendationsList() {
      return {
        diseases: this.recommendations.diseases
          .filter(
            (el) =>
              this.currentDiseases.includes(el.id) &&
              this.currentNosologys.includes(el.parent_id)
          )
          .map(function (item) {
            let created_arr = item.content_created
              .filter(
                (el) => !item.content_updated.find((up) => up.id === el.id)
              )
              .map(function (created) {
                created.updated_at = null;
                return {
                  ...created,
                  ...item,
                  name: created.title,
                  label: "Новый раздел",
                  child_id: created.id,
                };
              });
            let updated_arr = item.content_updated.map(function (updated) {
              updated.created_at = null;
              return {
                ...updated,
                ...item,
                name: updated.title,
                label: "Раздел обновлен",
                child_id: updated.id,
              };
            });
            return created_arr.concat(updated_arr);
          })
          .flat(1),
        drugs: this.recommendations.drugs.filter(
          (el) =>
            (!el.nosology.length || el.nosology.some((r) => this.currentNosologys.includes(r.id))) &&
            (!el.disease.length || el.disease.some((r) => this.currentDiseases.includes(r.id)))
        ),
        events: this.recommendations.events.filter((el) =>
          el.nosology.some((r) => this.currentNosologys.includes(r.id))
        ),
        podcasts: this.recommendations.podcasts.filter(
          (el) =>
            el.nosology.some((r) => this.currentNosologys.includes(r.id)) &&
            el.disease.some((r) => this.currentDiseases.includes(r.id))
        ),
        text_materials: this.recommendations.text_materials.filter(
          (el) =>
            el.nosology.some((r) => this.currentNosologys.includes(r.id)) &&
            el.disease.some((r) => this.currentDiseases.includes(r.id))
        ),
        video_materials: this.recommendations.video_materials.filter(
          (el) =>
            el.nosology.some((r) => this.currentNosologys.includes(r.id)) &&
            el.disease.some((r) => this.currentDiseases.includes(r.id))
        ),
      };
    },
  },
  methods: {
    ...mapActions([
      "getRecommendations",
      "fetchVideoFavorite",
      "fetchPodcastFavorite",
      "fetchTextFavorite",
      "fetchMedicationFavorite",
      "fetchVideoWatchLater",
      "fetchPodcastWatchLater",
      "fetchTextWatchLater",
      "fetchMedicationWatchLater",
    ]),
    loadMore(type) {
      this.listLength =
        this.listLength + this.count > this.recommendationsList[type].length
          ? this.recommendationsList[type].length
          : this.listLength + this.count;
    },
    toTop() {
      document.body.scrollIntoView({ behavior: "smooth" });
    },
    materialsListLength(type) {
      return this.listLength > this.recommendationsList[type].length
        ? this.recommendationsList[type].length
        : this.listLength;
    },
     async watchLater(method, slug, type) {
      switch (type) {
        case "video_material":
          await this.fetchVideoWatchLater({
            method: method,
            slug: slug,
            favoritePage: true,
          });
          break;
        case "podcast":
          await this.fetchPodcastWatchLater({
            method: method,
            slug: slug,
            favoritePage: true,
          });
          break;
        case "text_material":
          await this.fetchTextWatchLater({
            method: method,
            slug: slug,
            favoritePage: true,
          });
          break;
        case "drug":
          await this.fetchMedicationWatchLater({
            method: method,
            slug: slug,
            favoritePage: true,
          });
          break;
      }
      await this.getRecommendations(this.user.id);
    },
    async favorite(method, slug, type) {
      switch (type) {
        case "video_material":
          await this.fetchVideoFavorite({
            method: method,
            slug: slug,
            favoritePage: true,
          });
          break;
        case "podcast":
          await this.fetchPodcastFavorite({
            method: method,
            slug: slug,
            favoritePage: true,
          });
          break;
        case "text_material":
          await this.fetchTextFavorite({
            method: method,
            slug: slug,
            favoritePage: true,
          });
          break;
        case "drug":
          await this.fetchMedicationFavorite({
            method: method,
            slug: slug,
            favoritePage: true,
          });
          break;
      }
      await this.getRecommendations(this.user.id);
    },
    openFilter() {
      bus.$emit('openFilter')
    }
  },
  mounted() {
    if (this.user && Object.keys(this.user).length) {
      this.getRecommendations(this.user.id);
    }
  },
  watch: {
    user() {
      if (
        this.user &&
        Object.keys(this.user).length &&
        this.recommendations &&
        !Object.keys(this.recommendations).length
      ) {
        this.getRecommendations(this.user.id);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.materials {
  width: 100%;
  min-height: 100%;
  background-color: #f8f8f8;
  padding-left: 32px;
  padding-top: 48px;
  padding-bottom: 80px;
  z-index: 1000;
  position: relative;
  @media screen and (max-width: 1220px) {
    padding-left: 0;
  }

  &__filter-button {
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  &__grid {
    overflow-x: hidden;
    display: grid;
    grid-template-columns: repeat(3, 280px);
    column-gap: 32px;
    row-gap: 32px;

    @media screen and (max-width: 1220px) {
      grid-template-columns: repeat(2, calc(50% - 16px));
      row-gap: 24px;
    }

    @media screen and (max-width: 767px) {
      grid-template-columns: repeat(1, 100%);
      column-gap: 0;
      row-gap: 24px;
    }
  }

  &__title {
    margin-bottom: 24px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 29px;
    color: #1f1f1f;
    @media screen and (max-width: 767px) {
      font-size: 18px;
      line-height: 22px;
    }
  }

  &__item-title {
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 29px;
    color: #1f1f1f;
  }
}

.materials-page {
  &__buttons {
    margin-top: 32px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media screen and (max-width: 1220px) {
      margin-bottom: 80px;
    }

    @media screen and (max-width: 767px) {
      margin-bottom: 0;
      flex-direction: column;
      align-items: stretch;
    }
  }
  &__load {
    width: 175px;
    @media screen and (max-width: 767px) {
      width: 100%;
      margin-bottom: 12px;
    }
  }

  &__up {
    margin-left: auto;
    width: 213px;

    @media screen and (max-width: 767px) {
      width: 100%;
      margin-left: 0;
    }
  }
}

.favorites {
  background-color: #f8f8f8;
  padding-left: 32px;
  padding-top: 48px;
  padding-bottom: 80px;
  z-index: 1000;
  position: relative;
  @media screen and (max-width: 1220px) {
    padding-left: 0;
  }

  &__title {
    margin-bottom: 24px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 29px;
    color: #1f1f1f;
  }
  &__descr {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #3c4242;
  }

  &__btn {
    max-width: fit-content;
    @media screen and (max-width: 767px) {
      width: 100%;
      max-width: none;
    }
  }
}
</style>
<style lang="scss">
.materials-slider {
  .material {
    height: 100%;

    &__image-container {
      height: 150px !important;
    }
  }
}
</style>

<style lang="scss">
.materials {
  & .preloader {
    width: 100%;
    height: calc(100% - 48px - 80px);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f8f8f8;

    & .lds-ring {
      width: 100px;
      height: 100px;

      & div {
        border-width: 6px;
        border-color: #830051 transparent transparent transparent;
      }
    }
  }
}
</style>